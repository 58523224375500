import React from 'react'
import Button from 'react-bootstrap/Button'

function LinkBadges(props) {
  let links = props.links
  let entries = Object.entries(links)
  const buttons = entries.map(([link_title, link_url]) => {
    let badgeProps = { title: link_title, link: link_url }
    return <LinkBadge {...badgeProps} />
  })
  return buttons
}

function LinkBadge(props) {
  return (
    <Button
      href={props.link}
      target="_blank"
      variant="outline-secondary"
      size="sm"
      className='me-2'
      style={{
        fontSize: 12,
        textTransform: 'uppercase',
      }}
    >
      {props.title}
    </Button>
  )
}

export default LinkBadges
