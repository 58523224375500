import React, { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { useParams } from 'react-router'

function fileURL(blogId) {
  const blogPath = 'https://goblinzone.neocities.org/blogposts/'
  return blogPath + blogId + '.md'
}

function useBlogText(blogId) {
  const [fileText, setFileText] = useState('')

  useEffect(() => {
    fetch(fileURL(blogId))
      .then((file) => file.text())
      .then((fileText) => setFileText(fileText))
      .catch((err) => console.error(err))
  }, [blogId])

  return fileText
}

function BlogView() {
  let { blogId } = useParams()
  let blogText = useBlogText(blogId)

  return (
    <div className="container mt-5">
      <div className="card">
        <div className="card-content mt-5 mx-5">
          <div className="content">
            <ReactMarkdown children={blogText} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlogView
