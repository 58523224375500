import React from 'react'
import DetailCard from './DetailCard'
import {Link} from 'react-router-dom'

function monthAndYearOf(date) {
  return date
}

function BlogCard(props) {
  const subtitle = monthAndYearOf(props.date)
  const cardProps = { ...props, subtitle: subtitle }

  return (
    <Link to={props.filename} className="text-decoration-none link-secondary">
      <DetailCard {...cardProps}/>
    </Link>
  )
}

export default BlogCard
