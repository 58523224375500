import React from 'react'
import Card from 'react-bootstrap/Card'
import LinkBadges from './LinkBadges'

function DetailCard(props) {
  let linkBadges
  if (props.links) {
    linkBadges = <LinkBadges links={props.links} />
  }

  return (
    <Card className="mt-3">
      <Card.Body>
        <Card.Title className="text-start">
          {props.title}
        </Card.Title>
        <Card.Subtitle className="mb-2 text-muted text-start">
          {props.subtitle}
        </Card.Subtitle>
        <Card.Text className="text-start">{props.desc}</Card.Text>
        {linkBadges}
      </Card.Body>
    </Card>
  )
}

export default DetailCard
