import React from 'react'
import Nav from 'react-bootstrap/Nav'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function IconLink(props) {
  return (
    <Nav.Link href={props.url}>
      <FontAwesomeIcon icon={props.icon} size="xl" />
    </Nav.Link>
  )
}

export default IconLink
