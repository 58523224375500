import Image from 'react-bootstrap/Image'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import { Row } from 'react-bootstrap'

function Home() {
  return (
    <Container className="text-center mt-5" width="300">
      <Row>
        <Col></Col>
        <Col className="content">
          <Image
            roundedCircle="true"
            width="300"
            height="300"
            src={require('../assets/chibi.png')}
            alt="An illustrated profile"
          ></Image>
          <h4 className="pt-2">Heya I'm</h4>
          <h1>Johnny Willcox-Beney</h1>
          <p className="py-1">
            I'm a data scientist and general systems nerd. Here's where I put my
            opinions on <strong>data, AI, tabletop RPGs</strong> and whatever
            else captures my short attention
          </p>
        </Col>
        <Col></Col>
      </Row>
    </Container>
  )
}

export default Home
