import BlogCard from '../components/BlogCard'
import CardDeck from '../components/CardDeck'

function Blog() {
  return (
    <CardDeck title="Blogposts">
      <BlogCard
        title="How I Approach Projects (Part 1: Getting Better Ideas)"
        date="April 2022"
        desc="First of a three(?) part series about how I started finishing projects. In this first part I talk about how I improved my idea generation process."
        filename="projects"
      />
    </CardDeck>
  )
}

export default Blog
