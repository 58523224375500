import React from 'react'
import { Link } from 'react-router-dom'

function Brand() {
  return (
    <Link class="navbar-brand" to="/">
      <strong class="main blackout">
        goblin<strong class="highlight blackout">.</strong>zone
      </strong>
    </Link>
  )
}

export default Brand
