import React from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Brand from './Brand'
import IconLink from './IconLink'
import { Link } from 'react-router-dom'
import { NavDropdown } from 'react-bootstrap'
import {
  faTwitter,
  faItchIo,
  faLinkedin,
  faGithub,
} from '@fortawesome/free-brands-svg-icons'
import useWindowSize from "../hooks/useWindowSize.js";

function FlatPageLinks() {
  return (
    <Nav className="me-auto my-2 my-lg-0">
      <Nav.Link as={Link} to="projects">
        Projects
      </Nav.Link>
      <Nav.Link as={Link} to="blog">
        Blog
      </Nav.Link>
    </Nav>
  )
}

function DropdownPageLinks() {
  return (
    <NavDropdown title="👀" menuVariant="dark">
      <NavDropdown.Item as={Link} to="projects">
        Projects
      </NavDropdown.Item>
      <NavDropdown.Item as={Link} to="blog">
        Blog
      </NavDropdown.Item>
    </NavDropdown>
  )
}

function PageLinks() {
  const thresholdWidth = 450
  const windowSize = useWindowSize()

  console.log(windowSize)

  if (windowSize.width > thresholdWidth) {
    return <FlatPageLinks />
  } else {
    return <DropdownPageLinks />
  }
}

function Header() {
  return (
    <Navbar bg="dark" variant="dark">
      <Container fluid>
        <Navbar.Brand as={Brand} />
        <PageLinks />
        <Nav className="ml-auto">
          <IconLink url="https://github.com/goblinzone" icon={faGithub} />
          <IconLink url="https://tolinky.itch.io/" icon={faItchIo} />
          <IconLink
            url="https://www.linkedin.com/in/johnnywb/"
            icon={faLinkedin}
          />
          <IconLink url="https://twitter.com/goblin_zone" icon={faTwitter} />
        </Nav>
      </Container>
    </Navbar>
  )
}

export default Header
