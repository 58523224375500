import React from 'react'
import { Container } from 'react-bootstrap'

function CardDeck(props) {
  return (
    <Container class="d-flex align-items-center flex-column">
      <h1 className="mt-3 blackout">{props.title}</h1>
      {props.children}
    </Container>
  )
}

export default CardDeck
