import CardDeck from '../components/CardDeck'
import DetailCard from '../components/DetailCard'

function Projects() {
  return (
    <>
    <CardDeck title="Technical Projects">
      <DetailCard
        title="goblin.zone"
        subtitle="React, React Bootstrap, React Routers"
        desc="I've been trying to make this site for a while, and after wanting to
            learn React for another project - I came back and tried to get it
            set up. I've really been enjoying the modularity of using React. It
            allows me to approach the website component by component rather than
            as a big spaghetti mess."
      />
      <DetailCard
        title="Emophecy"
        links={{
          link: 'http://emophecy.neocities.org',
          "polygon's piece":
            'https://www.polygon.com/tabletop-games/22916218/wordle-game-jam-interview-tabletop-rpg',
          'wordle jam': 'https://itch.io/jam/the-wordle-jam',
        }}
        subtitle="JS, Bootstrap, Tracery"
        desc="Made for the 2022 Wordle Jam, Emophecy generates a daily emoji fortune. Based on my prior project Blaseballers, this uses cookies to keep track of the user and keep their fortune consistent each day. Was mentioned (very briefly) on Polygon. "
      />
      <DetailCard
        title="Blaseballers"
        links={{
          website: 'https://blaseballers.neocities.org/',
          'twitter bot': 'twitter.com/blaseballers',
          grammar: 'https://cheapbotsdonequick.com/source/blaseballers',
          github: 'https://github.com/goblinzone/blaseballers',
        }}
        subtitle="JS, Bootstrap, Tracery"
        desc="Generates weird baseball players. resurrecting my webdev skills and my first foray into using Tracery as part of a website. Also a first foray into using Emoji as a prompt for fiction, which I would touch on a little more with Emophecy. Overall a fun diversion. The highlight was, without a doubt, using python to grab the title, word and city lists from kaggle datasets - so this bot has around 50k text components to pull from and is constantly surprising me."
      />
      <DetailCard
        title="Multilingual Detection of Offensive Language on Social Media"
        links={{
          video: 'https://youtu.be/8LhvLePxiew',
        }}
        subtitle="Python, HuggingFace Transformers, Pandas, MatPlotLib"
        desc="My masters project from uni. I played around with using the multilingual capacity of XLMRoberta to allow for transfer-learning between datasets in different languages. While the results ended up being pretty dire, and I wasn't able to implement the features which would have made this paper worthy (upsampling through translation and a demonstration of 0-shot learning), I'm very proud of the analysis I did. I used t-SNE to identify similar samples and clustered them for analysis. I was able to find out that offensive posts are longer, on average, than inoffensive posts. I found that when two languages use the same script, that is detrimental to XLMRoberta's transfer learning. I also found that alignment of the samples would greatly improve the cross-lingual transfer capabilities."
      />
      </CardDeck>
      <CardDeck title="Non-Technical Projects">
      <DetailCard
        title="BigFest 2024"
        links={{
          link: 'https://tolinky.itch.io/bigfest-2024',
        }}
        subtitle="TTRPG"
        desc="A mystery for Monster of the Week. I originally wrote this for a private campaign before the pandemic but decided to polish it and release it just to derust and get back into the creative groove."
      />
      <DetailCard
        title="The Cook: A Playbook for Dungeon World"
        links={{
          link: 'https://tolinky.itch.io/the-cook',
        }}
        subtitle="TTRPG"
        desc="A playbook for the TTRPG Dungeon World. I really wanted to lean into the fiction-forward aspect of Dungeon World with a Support Class that is more of a Supporting Character. Their moves interrogate the GM, set up scenes and celebrate the other characters at the table. Over all I'm really happy with how it turned out, and I'm building on these ideas further in my upcoming game Biota."
      />
      <DetailCard
        title="Presence Games"
        links={{
          link: 'https://tolinky.itch.io/presence-games',
        }}
        subtitle="TTRPG"
        desc="Made for the business card jam, this game compiles seven one-sentence games I play when I'm overwhelmed or stressing out. They encourage you to focus on the present."
      />
    </CardDeck>
    </>
  )
}

export default Projects
